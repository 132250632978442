import axios from "axios";

import qs from "qs";
import {
  Message,
  Loading,
  MessageBox
} from "element-ui";
import router from "../router";
import {
  Local
} from '@/utils/storage'


let url = "https://store.pingtaiguanjia.com/ehomehelp/";
// let url = "http://127.0.0.1:10003/ehomehelp/";

export let API = url
// let API = "https://api.msjyjz.com"
let loading;
const Axios = axios.create({
  baseURL: API, // 因为我本地做了反向代理
  timeout: 10000,
  responseType: "json",
  withCredentials: false, // 是否允许带cookie这些
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  }
});

let showMsg = true;
let source = axios.CancelToken.source()
var loadingCount = 0
let addLoading = () => {
  loadingCount++;

  loading = Loading.service({
    lock: true,
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)"
  });
}

const isCloseLoading = () => {
  loadingCount--;
  if (loadingCount <= 0 && loading != undefined) {
    loading.close();
  }
}


//POST传参序列化(添加请求拦截器)
Axios.interceptors.request.use(
  config => {

    // 在发送请求之前做某件事
    if (config.method === "post") {
      if (!config.hideLoading) {
        if (config.headers.canLoading == 'true') {
          isCloseLoading()
        } else {
          addLoading()
        }
      }
      if (
        config.headers["Content-Type"] ===
        "application/x-www-form-urlencoded;charset=utf-8"
      ) {
        // 序列化
        config.data = qs.stringify(config.data);
      }
    }
    if (config.url != "/api/login/door" || config.url != "/api/sms/sendSms") {
      if (Local.get('userToken')?.data) {
        config.headers["X-TOKEN"] = Local.get('userToken').data
      }
    }
    return config;
  },
  error => {
    Message({
      //  饿了么的消息弹窗组件,类似toast
      showClose: true,
      message: error,
      type: "error"
    });
    return Promise.reject(error.data.error.message);
  }
);

// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(
  res => {
    isCloseLoading()
    let data = res.data;
    let success = data.code === 0 || data.status === 0;

    if (data.status === 10) {
      if (showMsg) {
        source.cancel('登陆信息已过期')
        MessageBox.alert(data.msg, '错误', {
          confirmButtonText: '确定',
          closeOnPressEscape: false,
          showClose: false,
          type: "error",
        }).then(() => {
          router.push("/login");
        })
        showMsg = false
        setTimeout(() => {
          showMsg = true;
        }, 3000);
      }
      return data;
    }
    let msg = data.msg;
    //对响应数据做些事
    if (!success) {
      Message({
        //  饿了么的消息弹窗组件,类似toast
        showClose: true,
        message: msg,
        type: "error"
      });

      return Promise.reject(data);
    }
    return Promise.resolve(data);
  },
  error => {
    isCloseLoading()
    Message({
      showClose: true,
      message: error.message,
      type: "error"
    });

    return Promise.reject(error);
  }
);

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: function (Vue, Option) {
    Object.defineProperty(Vue.prototype, "$api", {
      value: Axios
    });
  },
  Axios,
  API
};
